import { Box, Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { GetReadyList } from "./get-ready-list.interface";

export const GetReadyListFields = ({ data }: { data: GetReadyList }) => {
  const { values, setFieldValue } = useFormikContext<GetReadyList>();
  const [checkBoxesEnabled, setCheckBoxesEnabled] =
    React.useState<Boolean>(false);

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.name, e.target.checked);
  };

  const onClickRenableCheckBoxes = async () => {
    setCheckBoxesEnabled(!checkBoxesEnabled);
  };

  React.useMemo(() => {
    // fetch(`http://localhost:3001/get-ready-list/${data._id}`, {
    fetch(`http://52.90.184.7:3001/get-ready-list/${data._id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });
  }, [values, data._id]);

  return (
    <>
      {/* <div>{JSON.stringify(disables.entries, null, 2)}</div> */}
      <Grid container spacing={0} width="100%">
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="pruneJuiceWaterFlush"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.pruneJuiceWaterFlush}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.pruneJuiceWaterFlush}
              />
            }
            label="Prune Juice and water flush"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="gtCare"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.gtCare}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.gtCare}
              />
            }
            label="GT Care"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="breakfastBites"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.breakfastBites}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.breakfastBites}
              />
            }
            label="Breakfast bites"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="brushTeeth"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.brushTeeth}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.brushTeeth}
              />
            }
            label="Brush Teeth"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="waterSipsAfterTeeth"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.waterSipsAfterTeeth}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.waterSipsAfterTeeth}
              />
            }
            label="Give a few sips of water after teeth"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="washFace"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.washFace}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.washFace}
              />
            }
            label="Wash face"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="feedOneAndMeds"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.feedOneAndMeds}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.feedOneAndMeds}
              />
            }
            label="Feed 1 and meds at 9am"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="fixFoodForDay"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.fixFoodForDay}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.fixFoodForDay}
              />
            }
            label="Fix food for the rest of the day"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="afosAndBilliesInZippy"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.afosAndBilliesInZippy}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.afosAndBilliesInZippy}
              />
            }
            label="AFOs & Billies in Zippy"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="putChairInZippy"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.putChairInZippy}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.putChairInZippy}
              />
            }
            label="Put chair on Zippy"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="checkSuppliesPutInZippy"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.checkSuppliesPutInZippy}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.checkSuppliesPutInZippy}
              />
            }
            label="Check diaper bag supplies, put in Zippy"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="trexoWalker"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.trexoWalker}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.trexoWalker}
              />
            }
            label="Trexo Walker"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="putNeuroNodeInZippy"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.putNeuroNodeInZippy}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.putNeuroNodeInZippy}
              />
            }
            label="Put Neuro Node in Zippy"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="outfitWithSocksAndScrunchies"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.outfitWithSocksAndScrunchies}
                onChange={handleCheckBoxChange}
                disabled={
                  !checkBoxesEnabled && values.outfitWithSocksAndScrunchies
                }
              />
            }
            label="Outfit with socks & scrunchies"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="prismGoggles"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.prismGoggles}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.prismGoggles}
              />
            }
            label="Prism Goggles"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="brushHair"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.brushHair}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.brushHair}
              />
            }
            label="Brush hair"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="getDressed"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.getDressed}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.getDressed}
              />
            }
            label="Get dressed"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="cleanTherapyTableAndPutAway"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.cleanTherapyTableAndPutAway}
                onChange={handleCheckBoxChange}
                disabled={
                  !checkBoxesEnabled && values.cleanTherapyTableAndPutAway
                }
              />
            }
            label="Clean therapy table and put away"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="feedTwo"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.feedTwo}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.feedTwo}
              />
            }
            label="Feed 2 at 11am"
          />
        </Grid>
      </Grid>
      <Box sx={{ paddingRight: "2em" }}>
        <Button
          variant={"contained"}
          onClick={onClickRenableCheckBoxes}
          fullWidth
        >
          {checkBoxesEnabled
            ? "Disable Checked Items"
            : "Make Checked Items Clickable"}
        </Button>
      </Box>
    </>
  );
};
