import { NightTimeList } from "./night-time-list.interface";

export class NightTimeListHelpers {
  static createNewNightTimeList() {
    const newList: NightTimeList = {
      feedThree: false,
      prismGoggles: false,
      dinnerBites: false,
      brushTeeth: false,
      waterSipsAfterTeeth: false,
      washFace: false,
      gtCare: false,
      brushHair: false,
      feedFour: false,
      readStory: false,
      thunderstormSounds: false,
      starTurtle: false,
      violetAndUnicorn: false,
      createdAt: new Date(),
    };

    return newList;
  }
}
