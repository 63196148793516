import { Box, Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { NightTimeList } from "./night-time-list.interface";

export const NightTimeListFields = ({ data }: { data: NightTimeList }) => {
  const { values, setFieldValue } = useFormikContext<NightTimeList>();
  const [checkBoxesEnabled, setCheckBoxesEnabled] =
    React.useState<Boolean>(false);

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.name, e.target.checked);
  };

  const onClickRenableCheckBoxes = async () => {
    setCheckBoxesEnabled(!checkBoxesEnabled);
  };

  React.useMemo(() => {
    // fetch(`http://localhost:3001/night-time-list/${data._id}`, {
    fetch(`http://52.90.184.7:3001/night-time-list/${data._id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });
  }, [values, data._id]);

  return (
    <>
      {/* <div>{JSON.stringify(disables.entries, null, 2)}</div> */}
      <Grid container spacing={0} width="100%">
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="feedThree"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.feedThree}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.feedThree}
              />
            }
            label="Feed 3 at 5pm"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="prismGoggles"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.prismGoggles}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.prismGoggles}
              />
            }
            label="Prism Goggles"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="dinnerBites"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.dinnerBites}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.dinnerBites}
              />
            }
            label="Dinner bites"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="brushTeeth"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.brushTeeth}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.brushTeeth}
              />
            }
            label="Brush Teeth"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="waterSipsAfterTeeth"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.waterSipsAfterTeeth}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.waterSipsAfterTeeth}
              />
            }
            label="Give a few sips of water after teeth"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="washFace"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.washFace}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.washFace}
              />
            }
            label="Wash face"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="gtCare"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.gtCare}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.gtCare}
              />
            }
            label="GT Care"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="brushHair"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.brushHair}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.brushHair}
              />
            }
            label="Brush hair"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="feedFour"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.feedFour}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.feedFour}
              />
            }
            label="Feed 4 at 8pm"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="readStory"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.readStory}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.readStory}
              />
            }
            label="Read Story"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="thunderstormSounds"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.thunderstormSounds}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.thunderstormSounds}
              />
            }
            label="Thunderstorm Sounds"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="starTurtle"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.starTurtle}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.starTurtle}
              />
            }
            label="Star Turtle"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="violetAndUnicorn"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={values.violetAndUnicorn}
                onChange={handleCheckBoxChange}
                disabled={!checkBoxesEnabled && values.violetAndUnicorn}
              />
            }
            label="Violet and Unicorn"
          />
        </Grid>
      </Grid>
      <Box sx={{ paddingRight: "2em" }}>
        <Button
          variant={"contained"}
          onClick={onClickRenableCheckBoxes}
          fullWidth
        >
          {checkBoxesEnabled
            ? "Disable Checked Items"
            : "Make Checked Items Clickable"}
        </Button>
      </Box>
    </>
  );
};
