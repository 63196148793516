import React from "react";
import { Form, Formik } from "formik";
import { GetReadyList } from "./get-ready-list.interface";
import { GetReadyListFields } from "./get-ready-list-fields";

export const GetReadyListForm = ({ data }: { data: GetReadyList }) => {
  return (
    <Formik initialValues={data} onSubmit={(values) => {}}>
      {() => (
        <Form>
          <GetReadyListFields data={data} />
        </Form>
      )}
    </Formik>
  );
};
