import { GetReadyList } from "./get-ready-list.interface";

export class GetReadyListHelpers {
  static createNewGetReadyList() {
    const now = new Date();
    const time = now.getHours();

    const newList: GetReadyList = {
      pruneJuiceWaterFlush: false,
      gtCare: false,
      breakfastBites: false,
      brushTeeth: false,
      waterSipsAfterTeeth: false,
      washFace: false,
      feedOneAndMeds: false,
      fixFoodForDay: false,
      afosAndBilliesInZippy: false,
      putChairInZippy: false,
      checkSuppliesPutInZippy: false,
      trexoWalker: false,
      putNeuroNodeInZippy: false,
      outfitWithSocksAndScrunchies: false,
      prismGoggles: false,
      brushHair: false,
      getDressed: false,
      cleanTherapyTableAndPutAway: false,
      feedTwo: false,
      nightTime: time > 16 ? true : false,
      createdAt: new Date(),
    };

    return newList;
  }
}
