import React from "react";
import { NightTimeListForm } from "./night-time-list-form";
import { NightTimeList } from "./night-time-list.interface";
import { Box, Button } from "@mui/material";
import { NightTimeListHelpers } from "./night-time-list.helpers";

export const NightTimeListView = ({
  nightTimeLists,
}: {
  nightTimeLists: NightTimeList[];
}) => {
  const [listToUse, setListToUse] = React.useState<NightTimeList>();
  const todaysList = nightTimeLists.find(
    (list) =>
      new Date(list.createdAt).toLocaleDateString() ===
      new Date().toLocaleDateString()
  );

  React.useMemo(() => {
    if (todaysList) setListToUse(todaysList);
  }, [todaysList]);

  const startNewDay = () => {
    const newList = NightTimeListHelpers.createNewNightTimeList();
    // fetch("http://localhost:3001/night-time-list", {
    fetch("http://52.90.184.7:3001/night-time-list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(newList),
    })
      .then((response) => response.json())
      .then((response) => setListToUse(response));
  };

  return (
    <>
      {listToUse ? (
        <>
          <Box sx={{ marginTop: "-3%", marginBottom: "-4%" }}>
            <center>
              <h2>Vivi's Night Time List</h2>
            </center>
          </Box>
          <Box sx={{ paddingLeft: "2em" }}>
            <NightTimeListForm data={listToUse} />
          </Box>
        </>
      ) : (
        <>
          <center>
            <h2>Vivi's Night Time List</h2>
            <h3>{new Date().toLocaleDateString()}</h3>
            <Button variant={"contained"} onClick={startNewDay}>
              Start a New Night Time List!
            </Button>
          </center>
        </>
      )}
    </>
  );
};
