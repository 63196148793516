import React from "react";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { GetReadyList } from "./get-ready-list";
import { GetReadyListView } from "./get-ready-list/get-ready-list-view";
import { Box, CircularProgress } from "@mui/material";
import { NightTimeListView } from "./night-time-list/night-time-list-view";
import { NightTimeList } from "./night-time-list";

function App() {
  const [getReadyLists, setGetReadyLists] = React.useState<GetReadyList[]>([]);
  const [nightTimeLists, setNightTimeLists] = React.useState<NightTimeList[]>(
    []
  );
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const now = new Date().getHours();

  React.useEffect(() => {
    if (!getReadyLists.length && now < 16) {
      // fetch("http://localhost:3001/get-ready-list")
      fetch("http://52.90.184.7:3001/get-ready-list")
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setGetReadyLists(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    } else if (!nightTimeLists.length) {
      // fetch("http://localhost:3001/night-time-list")
      fetch("http://52.90.184.7:3001/night-time-list")
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setNightTimeLists(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [getReadyLists, nightTimeLists]);

  console.log(error);

  return (
    <div className="App">
      {!isLoaded ? (
        <CircularProgress />
      ) : now < 16 ? (
        <>
          <Box>
            <GetReadyListView getReadyLists={getReadyLists} />
          </Box>
        </>
      ) : (
        <>
          <Box>
            <NightTimeListView nightTimeLists={nightTimeLists} />
          </Box>
        </>
      )}
    </div>
  );
}

export default App;
