import React from "react";
import { Form, Formik } from "formik";
import { NightTimeList } from "./night-time-list.interface";
import { NightTimeListFields } from "./night-time-list-fields";

export const NightTimeListForm = ({ data }: { data: NightTimeList }) => {
  return (
    <Formik initialValues={data} onSubmit={(values) => {}}>
      {() => (
        <Form>
          <NightTimeListFields data={data} />
        </Form>
      )}
    </Formik>
  );
};
